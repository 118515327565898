
import React from 'react';
import saskPork from '../Images/Sask+Pork_Primary+Vertical_RGB.png';
import ontarioBeef from '../Images/BeefOntario.png';
import mtas from '../Images/MTAS-logo-400x143.jpg';
import publicHealthC from '../Images/Public Health Agency of Canada (PHAC).jpg';
import acfa from '../Images/ACFA-web-logo.png';
import bcrc from '../Images/BCBR-design-394x330.png';
import uniSask from '../Images/University of Saskatchewan .jpg';
import canadianWild from '../Images/Canadian Cooperative Wildlife Health Centre .jpg';
import '../App.css';


const CompanyLogos = () => {

const companies = [
    { name: 'saskPork', logo: saskPork, url: 'https://www.saskpork.com' },
    { name: 'ontarioBeef', logo: ontarioBeef, url: 'https://www.ontariobeef.com' },
    { name: 'mtas', logo: mtas, url: 'https://www.saskmassagetherapy.com' },
    { name: 'publicHealth', logo: publicHealthC, url: 'https://www.canada.ca/en/public-health.html' },
    { name: 'acfa', logo: acfa, url: 'https://cattlefeeders.ca/' },
    { name: 'bcrc', logo: bcrc, url: 'https://www.beefresearch.ca/' },
    { name: 'universitySask', logo: uniSask, url: 'https://www.usask.ca' },
    { name: 'canadianWild', logo: canadianWild, url: 'https://www.cwhc-rcsf.ca/' },
  ];

  // Inline styles
  const sectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the content
    width: '100vw', // Full viewport width
    margin: '20px 0',
  };
  const carouselContainerStyle = {
    width: '800px', // Set the width of the carousel
    overflow: 'hidden', // Hide overflow to create a carousel effect
    position: 'relative', // Positioning context for absolute elements
  };

  const logoContainerStyle = {
    display: 'flex',
    position: 'relative', // Required for absolute positioning
    animation: 'flow 20s linear infinite', // Animation for flowing effect
    whiteSpace: 'nowrap', // Prevent logos from wrapping to a new line
  };
  const logoStyle = {
    maxWidth: '100px', // Set a max width for logos
    maxHeight: '50px', // Set a max height for logos
    margin: '0 15px', // Add margin between logos
  };

  // Generate logos array for seamless effect
  const logoArray = [...companies, ...companies]; // Duplicate array for continuous flow

  return (
    <section style={sectionStyle}>
        <div style={carouselContainerStyle}>
      <h2>Notable Project Clients</h2>
      <div style={logoContainerStyle}>
          {logoArray.map((company, index) => (
            <a key={index} href={company.url} target="_blank" rel="noopener noreferrer">
            <img
              key={index}
              src={company.logo}
              alt={company.name}
              style={logoStyle}
            />
            </a>
        ))}
      </div>
</div>
    </section>
  );
};

// Add this global CSS style for the animation
const style = document.createElement('style');
style.textContent = `
  @keyframes flow {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
`;
document.head.append(style);

export default CompanyLogos;


