import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import logo8 from './Images/YellowrayLogo8.png';

function App() {
 
  return (
    <Router>
    <div className="App">
    {/* <div className="header-contact">
    <i className="fas fa-envelope"></i>
            <a href="mailto:info@yrcgi.com" className="email-link">info@yrcgi.com.com</a>
          </div> */}
      <header className="App-header">
      
      <div className="header-logo">
      <a href="/"> {/* Link to the homepage */}
        <img src={logo8} alt="Yellow Ray Consulting Logo" className="logo" />
      </a>
          </div> 
          <div className='nav-container'>
          <nav className="header-nav">
        
          <Link to="/">Home</Link>
          <Link to="/Services">Company</Link>
          <Link to="/contact">Contact Us</Link>
      
        </nav>
        </div>
      </header>

      <div className="App-content"> 
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
        </Routes>
        </div>

        <footer>
  <div className="footer-content">
    <ul className="social-icons">
      <li>
        <a
          href="https://www.linkedin.com/company/yellowray-consulting-group/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-linkedin fa-2x"></i>
        </a>
      </li>
      <li>
        <a
          href="https://yellowray.solutions"
          target="_blank"
          rel="noreferrer"
          data-tooltip="Visit Our Solutions Site"
        >
          <i className="fas fa-globe fa-2x"></i> {/* Web icon */}
        </a>
      </li>
    </ul>
    <p>&copy; {new Date().getFullYear()} Yellow Ray Consulting Group Inc. All Rights Reserved.</p>
  </div>
</footer>
    </div>
    </Router>
  );
}

export default App;
