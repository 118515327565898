import React from 'react';

const ContactUs = () => {
  // Using JavaScript to construct the email address
  const emailUser = 'info';
  const emailDomain = 'yrcgi.com';
  const email = `${emailUser}@${emailDomain}`;

  return (
    <section id="contact" className="contact-section">
      <h2>Contact Us</h2>
      <p>
        For any inquiries, please feel free to reach out to us via email at:
      </p>
      <p>
        <a href={`mailto:${email}`} className="contact-email">
          {email}
        </a>
      </p>
      <p>
        We look forward to hearing from you!
      </p>
    </section>
  );
};

export default ContactUs;

// import React, { useState } from 'react';
// import axios from 'axios';

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     businessName: '',
//     email: '',
//     phone: '',
//     message: '',
//   });
  
//   const [captchaToken, setCaptchaToken] = useState('');

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData({ ...formData, [id]: value });
//   };

//     // Function to handle the reCAPTCHA token
//     const handleCaptcha = (token) => {
//       setCaptchaToken(token); // Store the reCAPTCHA token
//     };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     if (!captchaToken) {
//       alert('Please complete the reCAPTCHA');
//       return;
//     }

//     try {
//       const response = await axios.post('http://localhost:3000/send', { ...formData, captchaToken });
//       alert('Message sent successfully!');
//       setFormData({ name: '', businessName: '', email: '', phone: '', message: '' });
//       setCaptchaToken('');
//       window.grecaptcha.reset(); // Reset reCAPTCHA after successful submission
//     } catch (error) {
//       alert('Error sending message. Please try again later.');
//       console.error(error);
//     }
//   };

//   return (
//     <section id="contact" className="contact-section">
//       <h2>Connect with our Team</h2>
//       <p>
//         Drop us a line about your project or describe a challenge your company needs help solving. We’d love to discuss how YRCGI can work with you. Please get in touch using the form below.
//       </p>
//       <form className="contact-form" onSubmit={handleSubmit}>
//         <div className="form-group">
//           <input 
//             type="text" 
//             id="name" 
//             placeholder="Name*" 
//             value={formData.name} 
//             onChange={handleChange} 
//             required 
//           />
//         </div>
//         {/* <div className="form-group">
//           <input 
//             type="text" 
//             id="business-name" 
//             placeholder="Company Name*" 
//             value={formData.businessName} 
//             onChange={handleChange} 
//             // required 
//           />
//         </div> */}
//         <div className="form-group">
//           <input 
//             type="email" 
//             id="email" 
//             placeholder="Company email*" 
//             value={formData.email} 
//             onChange={handleChange} 
//             required 
//           />
//         </div>
//         <div className="form-group">
//           <input 
//             type="tel" 
//             id="phone" 
//             placeholder="Phone number" 
//             value={formData.phone} 
//             onChange={handleChange} 
//           />
//         </div>
//         <div className="form-group">
//           <textarea 
//             id="message" 
//             placeholder="How we can help you?*" 
//             value={formData.message} 
//             onChange={handleChange} 
//             required 
//           ></textarea>
//         </div>
//         <div className="g-recaptcha" data-sitekey="YOUR_RECAPTCHA_SITE_KEY" data-callback={handleCaptcha}></div>
//         <button type="submit" className="submit-button">Send Message</button>
//       </form>
//       <script src="https://www.google.com/recaptcha/api.js" async defer></script>
//     </section>
//   );
// };

// export default Contact;



// import React, { useState } from 'react';
// import axios from 'axios';

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     businessName: '',
//     email: '',
//     phone: '',
//     message: '',
//   });
  
//   const [captchaToken, setCaptchaToken] = useState('');

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData({ ...formData, [id]: value });
//   };

//   const handleCaptcha = (token) => {
//     setCaptchaToken(token);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!captchaToken) {
//       alert('Please complete the reCAPTCHA');
//       return;
//     }

//     try {
//       await axios.post('http:/localhost:3000/send', { ...formData, captchaToken });
//       alert('Message sent successfully!');
//       setFormData({ name: '', businessName: '', email: '', phone: '', message: '' });
//       setCaptchaToken('');
//     } catch (error) {
//       alert('Error sending message. Please try again later.');
//       console.error(error);
//     }
//   };

//   return (
//     <section id="contact" className="contact-section">
//       <h2>Connect with our Team</h2>
//       <p>
//         Drop us a line about your project or describe a challenge your company needs help solving. We’d love to discuss how YRCGI can work with you. Please get in touch using the form below.
//       </p>
//       <form className="contact-form">
//       <div className="form-group">
//           <input type="text" id="name" placeholder="Name*" required />
//         </div>
//         <div className="form-group">
//           <input type="text" id="business-name" placeholder="Company Name*" required />
//         </div>
//         <div className="form-group">
//           <input type="email" id="email" placeholder="Company email*" required />
//         </div>
//         <div className="form-group">
//           <input type="tel" id="phone" placeholder="Phone number" />
//         </div>
//         <div className="form-group">
//           <textarea id="message" placeholder="How we can help you?*" required></textarea>
//         </div>
//         <div className="g-recaptcha" data-sitekey="YOUR_RECAPTCHA_SITE_KEY" onChange={handleCaptcha}></div>
//         <button type="submit" className="submit-button">Send Message</button>
//       </form>
//       <script src="https://www.google.com/recaptcha/api.js" async defer></script>
//     </section>
//   );
// };
// export default Contact;
